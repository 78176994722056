import React, {
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useState,
  useRef,
} from "react";
import MaterialReactTable from "material-react-table";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { AccountContext } from "../Account";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  ListItemIcon,
  Typography,
  Modal,
} from "@mui/material";
import {
  Delete,
  Edit,
  OpenInNew,
  Download,
  Settings,
  ConfirmationNumber,
  ConfirmationNumberTwoTone,
  SaveAltRounded,
  Save,
  Done,
} from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import ReactEcharts from "echarts-for-react";
import FullScreenSvg from "../assests/fullscreen.svg"
const SelectedIzci = () => {
  const { apiKey, user_id, userMail } = useContext(AccountContext);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [postdata, setpostdata] = useState(false);
  const [lastTime, setLastTime] = useState();
  const [tsdata, settsdata] = useState();
  const [filteredtsdata, setfilteredtsdata] = useState();
  const { id } = useParams();
  const [SancakPacketVersion, setSancakPacketVersion] = useState("");
  const [TPKPacketVersion, setTPKPacketVersion] = useState("");
  const [TPKMeasureCount, setTPKMeasureCount] = useState(0);
  const [TPKSleepTime, setTPKSleepTime] = useState(60);
  const [isConfigModal, setIsConfigModal] = useState(false);
  const [configLoading, setConfigLoading] = useState(false);
  const [showGraph, setShowGraph] = useState(() => {
    if (window?.navigator?.userAgentData?.mobile) {
      return false;
    } else if (!window?.navigator?.userAgentData) {
      return false;
    } else {
      return true;
    }
  });
  const [columnObj, setColumnObj] = useState([]);
  useEffect(() => {
    console.log("id :::", id);
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const resultfilter = result.body;

        // setTableData([resultfilter]);
        
        fetch(
          `https://g0vud29n03.execute-api.eu-west-1.amazonaws.com/Stage1?id=${resultfilter.id}&type=tabular&izcilength=${resultfilter.izci_length}`,
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((result) => {
            var asd = JSON.stringify(result.body);
            console.log('new type data : ', result);
            var obj = [];
            result.body.map((e) => {
              // console.log('aha bu e:', e);
              obj.push(JSON.parse(e));
            });
            console.log('objilk:', obj);
            setTableData([{...resultfilter , "red_led_status":obj[0].red_led_status,"green_led_status":obj[0].green_led_status,"motor_status":obj[0].motor_status}])
            settsdata(obj);
            var colmndata =[]
              var keys =Object.keys(obj?.[0])
              // console.log("keys66",keys);
              keys.map((e) => {
                  // console.log("key",e)
                  let tempcol = {}
                  if(e != "received_date" && e!='measure_name'){
                    tempcol.accessorKey=e
                  tempcol.header=e
                  tempcol.size=80
                  if (e=='event_date' ) {
                    tempcol.accessorFn = (originalRow) => {
                      var mdate = new Date(Number(originalRow.event_date));
                      mdate.setMinutes(mdate.getMinutes() );
                      
                      originalRow.event_date = mdate;
                      return `       ${mdate.getHours()}:${
                        mdate.getMinutes() < 10
                          ? `0${mdate.getMinutes()}`
                          : mdate.getMinutes()
                      }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
                      `;
                    }
                  }
                  if (e=='time') {
                    tempcol.accessorFn = (originalRow) => {
                      var mdate = new Date(originalRow.time);
                      mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
                      
                      originalRow.time = mdate;
                      return `       ${mdate.getHours()}:${
                        mdate.getMinutes() < 10
                          ? `0${mdate.getMinutes()}`
                          : mdate.getMinutes()
                      }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
                      `;
                    }
                  }
                  
                  colmndata.push(tempcol)
                  }
                  
                  
              });
              
              console.log("colmndata",colmndata);
                  setColumnObj(colmndata)
          })
          .then(() => setfull(true));
      })

      .catch((error) => console.log("error", error));
    const interval = setInterval(() => {
      fetch(
        `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          const resultfilter = result.body;

          // setTableData([resultfilter]);
          fetch(
            `https://g0vud29n03.execute-api.eu-west-1.amazonaws.com/Stage1?id=${resultfilter.id}&type=tabular&izcilength=${resultfilter.izci_length}`,
            {
              method: "GET",
            }
          )
            .then((response) => response.json())
            .then((result) => {
              var asd = JSON.stringify(result.body);
              console.log('new type data : ', result);
              var obj = [];
              result.body.map((e) => {
                // console.log('aha bu e:', e);
                obj.push(JSON.parse(e));
              });
              console.log('objilk:', obj);
   
              setTableData([{...resultfilter , "red_led_status":obj[0].red_led_status,"green_led_status":obj[0].green_led_status,"motor_status":obj[0].motor_status}])
              settsdata(obj);
              var colmndata =[]
                var keys =Object.keys(obj?.[0])
                // console.log("keys66",keys);
                keys.map((e) => {
                    // console.log("key",e)
                    let tempcol = {}
                    if(e != "received_date" && e!='measure_name'){
                      tempcol.accessorKey=e
                    tempcol.header=e
                    tempcol.size=80
                    if (e=='event_date' ) {
                      tempcol.accessorFn = (originalRow) => {
                        var mdate = new Date(Number(originalRow.event_date));
                        mdate.setMinutes(mdate.getMinutes() );
                        
                        originalRow.event_date = mdate;
                        return `       ${mdate.getHours()}:${
                          mdate.getMinutes() < 10
                            ? `0${mdate.getMinutes()}`
                            : mdate.getMinutes()
                        }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
                        `;
                      }
                    }
                    if (e=='time') {
                      tempcol.accessorFn = (originalRow) => {
                        var mdate = new Date(originalRow.time);
                        mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
                        
                        originalRow.time = mdate;
                        return `       ${mdate.getHours()}:${
                          mdate.getMinutes() < 10
                            ? `0${mdate.getMinutes()}`
                            : mdate.getMinutes()
                        }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
                        `;
                      }
                    }
                    
                    colmndata.push(tempcol)
                    }
                    
                    
                });
                
                console.log("colmndata",colmndata);
                    setColumnObj(colmndata)
            });
        })
        .then(() => setfull(true))
        .catch((error) => console.log("error", error));
    }, 5000);
    return () => clearInterval(interval);
  }, [postdata]);

  const FilterTimeFunction = (timeP)=>{
    var reqTime = new Date();
    var filteredArr;
    switch (timeP) {
      case "1m":
        // Tarihi bir ay öncesine göre 
    reqTime.setMonth(reqTime.getMonth() - 1);
    filteredArr = tsdata.filter(obje => Date.parse(obje.time) >= Date.parse(reqTime));
    setfilteredtsdata(filteredArr)
        break;
      case "6m":
        reqTime.setMonth(reqTime.getMonth() - 6);
        filteredArr = tsdata.filter(obje => Date.parse(obje.time) >= Date.parse(reqTime));
        setfilteredtsdata(filteredArr)
      break;
      case "1y":
        reqTime.setMonth(reqTime.getMonth() - 12);
        filteredArr = tsdata.filter(obje => Date.parse(obje.time) >= Date.parse(reqTime));
        setfilteredtsdata(filteredArr)
      break;
      case "all":
        
        setfilteredtsdata(tsdata)
      break;
    }
    
  }
  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var updatedValues = values;
      var id = values.id;
      delete updatedValues.id;
      delete updatedValues.produced_at;

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(values),
      };
      console.log(values);
      fetch(
        `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));

      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
      //
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue("id")}`)
      ) {
        return;
      }
      console.log("deleted");
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
      };

      fetch(
        `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${row.getValue(
          "id"
        )}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );
  function setLast(time) {
    var mdate = new Date(time);
    mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
    console.log("time", mdate.getTime());

    return `       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
  `;
  }
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        enableClickToCopy: true,
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          console.log("originalRow",originalRow )
          return <div className={` ${originalRow.red_led_status == 1 ? "bg-red-600" : originalRow.green_led_status == 1 ? "bg-green-600" : "bg-gray-300" }   w-16 h-16 rounded-full`}></div>
        },
        header: "led_status",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          console.log("originalRow",originalRow )
          return <div className={``}>{originalRow.motor_status}</div>
        },
        header: "motor_status",
        size: 80,
      },
      {
        accessorKey: "qr",
        header: "qr",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => (
          <>
            <QRCode
              id={cell.row.original.id}
              size={256}
              style={{
                height: "auto",
                width: "70px",
              }}
              value={cell.row.original.id}
              viewBox={`0 0 256 256`}
            />
          </>
        ),
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const columns90 = useMemo(
    () => [
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow.event_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow.time);
          mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());

          originalRow.time = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "time",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "btv",
        header: "btv",
        size: 80,
      },
      {
        accessorKey: "snr",
        header: "snr",
        size: 80,
      },
      {
        accessorKey: "rssi",
        header: "rssi",
        size: 80,
      },

      {
        accessorKey: "error",
        header: "error",
        size: 80,
      },

      {
        accessorKey: "at",
        header: "at",
        size: 80,
      },
      {
        accessorKey: "sm5",
        header: "sm5",
        size: 80,
      },
      {
        accessorKey: "sm15",
        header: "sm15",
        size: 80,
      },

      {
        accessorKey: "sm25",
        header: "sm25",
        size: 80,
      },
      {
        accessorKey: "sm35",
        header: "sm35",
        size: 80,
      },
      {
        accessorKey: "sm45",
        header: "sm45",
        size: 80,
      },
      {
        accessorKey: "sm55",
        header: "sm55",
        size: 80,
      },
      {
        accessorKey: "sm65",
        header: "sm65",
        size: 80,
      },
      {
        accessorKey: "sm75",
        header: "sm75",
        size: 80,
      },
      {
        accessorKey: "st5",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "st15",
        header: "st15",
        size: 80,
      },
      {
        accessorKey: "st25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "st35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "st45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "st55",
        header: "st55",
        size: 80,
      },
      {
        accessorKey: "st65",
        header: "st65",
        size: 80,
      },
      {
        accessorKey: "st75",
        header: "st75",
        size: 80,
      },
      {
        accessorKey: "st85",
        header: "st85",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow.sf5) / 1000000).toFixed(3);
        },
        header: "sf5",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow.sf15) / 1000000).toFixed(3);
        },
        header: "sf15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return (Number(originalRow.sf25) / 1000000).toFixed(3);
        },
        header: "sf25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow.sf35) / 1000000).toFixed(3);
        },
        header: "sf35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow.sf45) / 1000000).toFixed(3);
        },
        header: "sf45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow.sf55) / 1000000).toFixed(3);
        },
        header: "sf55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow.sf65) / 1000000).toFixed(3);
        },
        header: "sf65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow.sf75) / 1000000).toFixed(3);
        },
        header: "sf75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow.sf85) / 1000000).toFixed(3);
        },
        header: "sf85",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.sf_sd5);
        },
        header: "sf_sd5",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.sf_sd15);
        },
        header: "sf_sd15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.sf_sd25);
        },
        header: "sf_sd25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.sf_sd35);
        },
        header: "sf_sd35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.sf_sd45);
        },
        header: "sf_sd45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.sf_sd55);
        },
        header: "sf_sd55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.sf_sd65);
        },
        header: "sf_sd65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.sf_sd75);
        },
        header: "sf_sd75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.sf_sd85);
        },
        header: "sf_sd85",
        size: 80,
      },
      {
        accessorKey: "sc5",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "sc15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "sc25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "sc35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "sc45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "sc55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "sc65",
        header: "sc65",
        size: 80,
      },
      {
        accessorKey: "sc75",
        header: "sc75",
        size: 80,
      },
      {
        accessorKey: "sc85",
        header: "sc85",
        size: 80,
      },
      {
        accessorKey: "scgb5",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "scgb15",
        header: "scgb15",
        size: 80,
      },

      {
        accessorKey: "scgb25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "scgb35",
        header: "scgb35",
        size: 80,
      },
      {
        accessorKey: "scgb45",
        header: "scgb45",
        size: 80,
      },
      {
        accessorKey: "scgb55",
        header: "scgb55",
        size: 80,
      },
      {
        accessorKey: "scgb65",
        header: "scgb65",
        size: 80,
      },
      {
        accessorKey: "scgb75",
        header: "scgb75",
        size: 80,
      },
      {
        accessorKey: "scgb85",
        header: "scgb85",
        size: 80,
      },
      {
        accessorKey: "vbus5",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "vbus15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "vbus25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "vbus35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "vbus45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "vbus55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "vbus65",
        header: "vbus65",
        size: 80,
      },
      {
        accessorKey: "vbus75",
        header: "vbus75",
        size: 80,
      },
      {
        accessorKey: "vbus85",
        header: "vbus85",
        size: 80,
      },
      {
        accessorKey: "vbusgb5",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "vbusgb15",
        header: "vbusgb15",
        size: 80,
      },

      {
        accessorKey: "vbusgb25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "vbusgb35",
        header: "vbusgb35",
        size: 80,
      },
      {
        accessorKey: "vbusgb45",
        header: "vbusgb45",
        size: 80,
      },
      {
        accessorKey: "vbusgb55",
        header: "vbusgb55",
        size: 80,
      },
      {
        accessorKey: "vbusgb65",
        header: "vbusgb65",
        size: 80,
      },
      {
        accessorKey: "vbusgb75",
        header: "vbusgb75",
        size: 80,
      },
      {
        accessorKey: "vbusgb85",
        header: "vbusgb85",
        size: 80,
      },
      {
        accessorKey: "tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const columns60 = useMemo(
    () => [...columnObj
      
    ],
    [columnObj,getCommonEditTextFieldProps]
  );
  const columns30 = useMemo(
    () => [
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow.event_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow.time);
          mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());

          originalRow.time = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "time",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "btv",
        header: "btv",
        size: 80,
      },
      {
        accessorKey: "snr",
        header: "snr",
        size: 80,
      },
      {
        accessorKey: "rssi",
        header: "rssi",
        size: 80,
      },

      {
        accessorKey: "error",
        header: "error",
        size: 80,
      },

      {
        accessorKey: "at",
        header: "at",
        size: 80,
      },
      {
        accessorKey: "sm5",
        header: "sm5",
        size: 80,
      },
      {
        accessorKey: "sm15",
        header: "sm15",
        size: 80,
      },

      {
        accessorKey: "sm25",
        header: "sm25",
        size: 80,
      },

      {
        accessorKey: "st5",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "st15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "st25",
        header: "st25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow.sf5) / 1000000).toFixed(3);
        },
        header: "sf5",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow.sf15) / 1000000).toFixed(3);
        },
        header: "sf15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return (Number(originalRow.sf25) / 1000000).toFixed(3);
        },
        header: "sf25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.sf_sd5);
        },
        header: "sf_sd5",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.sf_sd15);
        },
        header: "sf_sd15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.sf_sd25);
        },
        header: "sf_sd25",
        size: 80,
      },
      {
        accessorKey: "sc5",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "sc15",
        header: "sc15",
        size: 80,
      },
      {
        accessorKey: "sc25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "scgb5",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "scgb15",
        header: "scgb15",
        size: 80,
      },
      {
        accessorKey: "scgb25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "vbus5",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "vbus15",
        header: "vbus15",
        size: 80,
      },
      {
        accessorKey: "vbus25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "vbusgb5",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "vbusgb15",
        header: "vbusgb15",
        size: 80,
      },
      {
        accessorKey: "vbusgb25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const downloadQRCode = (row) => {
    console.log("row : ", row.original);
    var svg = document.getElementById(row.original.id);

    // Create a container SVG element
    let containerSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    containerSvg.setAttribute("xmlns", "http://www.w3.org/2000/svg");

    // Create a group element for QR code
    let qrGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
    qrGroup.setAttribute("transform", "translate(50, 0)"); // QR kodunu sağa 20 birim kaydır

    // Append QR code SVG to QR group
    qrGroup.appendChild(svg.cloneNode(true));

    // Append QR group to container SVG
    containerSvg.appendChild(qrGroup);

    // Create a text element for QR code value
    let qrValueText = document.createElementNS("http://www.w3.org/2000/svg", "text");
    qrValueText.textContent = row.original.id;
    qrValueText.setAttribute("x", "0");
    qrValueText.setAttribute("y", (parseInt(svg.getAttribute("height")) + 50).toString());
    qrValueText.setAttribute("font-family", "Arial");
    qrValueText.setAttribute("font-size", "40"); // Yazı boyutunu ayarla

    // Append text element to container SVG
    containerSvg.appendChild(qrValueText);

    // Convert container SVG to XML string
    var svgSource = new XMLSerializer().serializeToString(containerSvg);

    // Convert SVG source to URI data scheme
    var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgSource);

    // Create a link element for download
    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${row.original.id}.svg`;

    // Append download link to body
    document.body.appendChild(downloadLink);

    // Trigger click event on download link to initiate download
    downloadLink.click();

    // Clean up: Remove download link from body
    document.body.removeChild(downloadLink);
};
  const [full, setfull] = useState(false);
  const [loading, setLoading] = useState(false);
  const [time, settime] = useState([]);
  const [value, setvalue] = useState([]);
  const [max, setmax] = useState();
  const [min, setmin] = useState();

  function yaz(data2,attrname) {
    var time = [];
    var value = [];
    var lasttime;
    data2?.map((data) => {
      if (Date.parse(data.time) - lasttime < -7200000) {
        value.push([Date.parse(data.time) + 3200000, null]);
      }
      var mdate = new Date(data.time);
      mdate.setMinutes(mdate.getMinutes());
      value.push([mdate, Number(data[attrname]).toFixed(2)]);

      lasttime = Date.parse(data.time);
    });
    return value;
  }

  const DrawStackedTSSM = (data) => {
    
    
        var graphData1 = yaz(data,"charge_current");
        var graphData2 = yaz(data,"bus_voltage");
        var graphData3 = yaz(data,"system_voltage");
        var graphData4 = yaz(data,"battery_voltage");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
          ],
          ["charge_current","bus_voltage","system_voltage","battery_voltage"]
        );
      
        var graphData1 = yaz(data,"sm5");
        var graphData2 = yaz(data,"sm15");
        var graphData3 = yaz(data,"sm25");
        return StackedLine(
          [graphData1, graphData2, graphData3],
          ["sm05", "sm15", "sm25"]
        );
    
  };
  const DrawStackedTSSMMemo = useMemo(
    () => DrawStackedTSSM( filteredtsdata ? filteredtsdata : tsdata),
    [filteredtsdata,tsdata]
  );
  const DrawStackedTSST = (data) => {
    switch (tableData && tableData[0]?.izci_length ? tableData[0].izci_length : "") {
      case "60":
        var graphData1 = yaz(data,"st5");
        return StackedLine(
          [
            graphData1,
          ],
          ["st05",]
        );
      case "90":
        var graphData1 = yaz(data,"st5");
        var graphData2 = yaz(data,"st15");
        var graphData3 = yaz(data,"st25");
        var graphData4 = yaz(data,"st35");
        var graphData5 = yaz(data,"st45");
        var graphData6 = yaz(data,"st55");
        var graphData7 = yaz(data,"st65");
        var graphData8 = yaz(data,"st75");
        var graphData9 = yaz(data,"st85");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
            graphData7,
            graphData8,
            graphData9,
          ],
          [
            "st05",
            "st15",
            "st25",
            "st35",
            "st45",
            "st55",
            "st65",
            "st75",
            "st85",
          ]
        );
      default:
        var graphData1 = yaz(data,"st5");
        var graphData2 = yaz(data,"st15");
        var graphData3 = yaz(data,"st25");
        return StackedLine(
          [graphData1, graphData2, graphData3],
          ["st05", "st15", "st25"]
        );
    }
  };
  const DrawStackedTSSTMemo = useMemo(
    () => DrawStackedTSST( filteredtsdata ? filteredtsdata : tsdata),
    [filteredtsdata,tsdata]
  );
  const DrawStackedTSAT = (data) => {
    var graphData1 = yaz(data,"at");
    return StackedLine([graphData1], ["at"]);
  };
  const DrawStackedTSATMemo = useMemo(
    () => DrawStackedTSAT( filteredtsdata ? filteredtsdata : tsdata),
    [filteredtsdata,tsdata]
  );
  const DrawStackedTSSF = (data) => {
    switch (tableData && tableData[0]?.izci_length ? tableData[0].izci_length : "") {
      case "60":
        var graphData1 = yaz(data,"sf5");
        var graphData2 = yaz(data,"sf15");
        var graphData3 = yaz(data,"sf25");
        var graphData4 = yaz(data,"sf35");
        var graphData5 = yaz(data,"sf45");
        var graphData6 = yaz(data,"sf55");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
          ],
          ["sf05", "sf15", "sf25", "sf35", "sf45", "sf55"]
        );
      case "90":
        var graphData1 = yaz(data,"sf5");
        var graphData2 = yaz(data,"sf15");
        var graphData3 = yaz(data,"sf25");
        var graphData4 = yaz(data,"sf35");
        var graphData5 = yaz(data,"sf45");
        var graphData6 = yaz(data,"sf55");
        var graphData7 = yaz(data,"sf65");
        var graphData8 = yaz(data,"sf75");
        var graphData9 = yaz(data,"sf85");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
            graphData7,
            graphData8,
            graphData9,
          ],
          [
            "sf05",
            "sf15",
            "sf25",
            "sf35",
            "sf45",
            "sf55",
            "sf65",
            "sf75",
            "sf85",
          ]
        );
      default:
        var graphData1 = yaz(data,"sf5");
        var graphData2 = yaz(data,"sf15");
        var graphData3 = yaz(data,"sf25");
        return StackedLine(
          [graphData1, graphData2, graphData3],
          ["sf05", "sf15", "sf25"]
        );
    }
  };
  const DrawStackedTSSFMemo = useMemo(
    () => DrawStackedTSSF( filteredtsdata ? filteredtsdata : tsdata),
    [filteredtsdata,tsdata]
  );
  var csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: tableData[0]?.izci_length
      ? tableData[0].izci_length == 60
        ? columns60.map((c) => c.header)
        : tableData[0].izci_length == 90
        ? columns90.map((c) => c.header)
        : columns30.map((c) => c.header)
      : "",
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(
      rows.map((row) => {
        var ordered = {};
        columns60.map((c) => (ordered[c.header] = row.original[c.header]));
        return ordered;
      })
    );
  };

  const ChangeConfigurationFunction = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var tempCont = Number(TPKMeasureCount).toString(16);
    if (tempCont.length == 1) {
      tempCont = `0${tempCont}`;
    }
    var tempSleep = Number(TPKSleepTime).toString(16);
    if (tempSleep.length == 1) {
      tempSleep = `00${tempSleep}`;
    } else if (tempSleep.length == 2) {
      tempSleep = `0${tempSleep}`;
    }
    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: Number(SancakPacketVersion),
      i: id,
      c: `${TPKPacketVersion}${tempCont.toUpperCase()}${tempSleep.toUpperCase()}`,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    console.log(body);
    fetch(
      `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/command?id=${id}&type=izci`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setConfigLoading(false);
      })
      .then(() => setIsConfigModal(false))
      .catch((error) => console.log("error", error));
  };

  const rowVirtualizerInstanceRef = useRef(null);

  function toggleFullscreen(selectedclass) {
    let elem = document.querySelector(`.${selectedclass}`);
    if (elem.requestFullscreen) {
      elem.requestFullscreen().catch((err) => {
            alert(
              `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
            );
          })
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
        );
      });
    }
    else if (elem.children[0].webkitEnterFullscreen) 
    { 
        elem.children[0].webkitEnterFullscreen();
        //toggle_controls(); //# your own function to show/hide iOS media controls
    } 
    else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
        );
      });
    }

    // if (!document.fullscreenElement) {
      
    //   elem.requestFullscreen().catch((err) => {
    //     alert(
    //       `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
    //     );
    //   });
    // } else {
    //   document.exitFullscreen();
    // }
  }
  console.log("gandalf",tableData)
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        // enableRowNumbers
        enableRowActions={userMail.includes('@yt.com.tr') ? true :false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableGlobalFilter={false}
        enableHiding={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableColumnActions={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        renderRowActionMenuItems={({ closeMenu, table, row }) => [
          <MenuItem
            key={0}
            onClick={() => {
              table.setEditingRow(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            Edit
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              handleDeleteRow(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            Delete
          </MenuItem>,

          <MenuItem
            key={3}
            onClick={() => {
              downloadQRCode(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            Download QR Code
          </MenuItem>,
        ]}
      />
      <>
        <h1 className="text-4xl m-5 flex flex-row max-sm:flex-col gap-3 ">
          Measurements{" "}
          <p className="flex items-end text-xl text-blue-600">
            last measure: {tsdata && setLast(tsdata[0]?.time)}
          </p>
        </h1>

        <div className="mb-4">
          {full ? (
            <MaterialReactTable
              columns={
                tableData[0]?.izci_length == 60
                  ? columns60
                  : tableData[0]?.izci_length == 90
                  ? columns90
                  : tableData[0]?.izci_length == 30
                  ? columns30
                  : []
              }
              data={full && tsdata && tsdata.length>0 ? tsdata : []}
              editingMode="modal" //default
              enableRowNumbers
              enableFullScreenToggle={true}
              enableDensityToggle={false}
              enableGlobalFilter={false}
              enableHiding={false}
              enableColumnFilters={false}
              enablePagination={true}
              autoResetPageIndex={false}
              enableRowVirtualization
              rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
              rowVirtualizerProps={{ overscan: 8 }} //optionally customize the virtualizer
              enableStickyHeader
              muiTablePaginationProps={{
                rowsPerPageOptions: [10, 50, 200, 1000],
                showFirstButton: false,
                showLastButton: false,
              }}
              enableColumnActions={true}
              enableBottomToolbar={true}
              enableTopToolbar={userMail.includes('@yt.com.tr') ? true :false}
              enableRowSelection={userMail.includes('@yt.com.tr') ? true :false}
              initialState={{
                density: "compact",
              }}
              state={{
                isLoading: !full,
              }}
              positionToolbarAlertBanner="bottom"
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "0.5rem",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    disabled={
                      table.getPrePaginationRowModel().rows.length === 0
                    }
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() =>
                      handleExportRows(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Export All Data
                  </Button>

                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    onClick={() =>
                      handleExportRows(table.getSelectedRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Export Selected Data
                  </Button>
                  {/* <Button
                    disabled={
                      table.getPrePaginationRowModel().rows.length === 0
                    }
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() => setIsConfigModal(true)}
                    startIcon={<Settings />}
                    variant="contained"
                  >
                    Change Configuration
                  </Button> */}
                </Box>
              )}
              muiTableProp
              muiTableBodyCellProps={{
                sx: {
                  m: "auto",
                  width: "260px !important",
                  maxWidth: "1000px !important",
                  minWidth: "200px !important",
                },
              }}
            />
          ) : (
            <div className=" flex flex-row flex-wrap gap-5 ml-5 mr-5 ">
              <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
            </div>
          )}
        </div>

        <Modal
          open={isConfigModal}
          onClose={() => setIsConfigModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3 max-sm:w-full bg-white rounded-md  shadow-24 p-4  flex flex-col">
            <p className="text-2xl w-full  text-center pt- pb-4">Config</p>
            <div className="flex flex-col gap-2">
              <TextField
                id="outlined-basic"
                label="Ocak Id"
                value={tsdata?.[0]?.ocak_id}
                variant="standard"
              />
              <TextField
                id="outlined-basic"
                label="Izci Id"
                value={id}
                variant="standard"
              />
              <TextField
                id="outlined-basic"
                label="Sancak Packet Version"
                variant="standard"
                value={SancakPacketVersion}
                onChange={(event) => {
                  setSancakPacketVersion(event.target.value);
                }}
              />
              <p className="pt-3 font-bold">TPK Configs </p>
              <div className="flex flex-col pl-4 gap-2">
                <TextField
                  id="outlined-basic"
                  label="TPK Packet Version"
                  value={TPKPacketVersion}
                  onChange={(event) => {
                    setTPKPacketVersion(event.target.value);
                  }}
                  variant="standard"
                />
                <TextField
                  id="outlined-basic"
                  label="TPK Measure Count (0 = infinity)"
                  value={TPKMeasureCount}
                  onChange={(event) => {
                    setTPKMeasureCount(event.target.value);
                  }}
                  variant="standard"
                />
                <TextField
                  id="outlined-basic"
                  label="TPK Sleep Time (minute)"
                  variant="standard"
                  value={TPKSleepTime}
                  onChange={(event) => {
                    setTPKSleepTime(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className=" p-4 w-full flex flex-row gap-2 justify-end">
              <Button
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() => setIsConfigModal(false)}
                variant="text"
              >
                Cancel
              </Button>
              <Button
                disabled={
                  SancakPacketVersion === "" ||
                  TPKPacketVersion === "" ||
                  TPKMeasureCount === "" ||
                  TPKSleepTime === ""
                }
                //export all rows, including from the next page, (still respects filtering and sorting)

                onClick={() => {
                  ChangeConfigurationFunction();
                  setConfigLoading(true);
                }}
                startIcon={configLoading ? " " : <Done />}
                variant="contained"
              >
                {configLoading ? (
                  <div className=" flex flex-row flex-wrap">
                    <div className=" m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-5 w-5"></div>
                  </div>
                ) : (
                  "Apply"
                )}
              </Button>
            </div>
          </Box>
        </Modal>
        {!full == true ? (
          <div className=" flex flex-row flex-wrap gap-5 ml-5 mr-5 ">
            <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
          </div>
        ) : (
          <div className={``}>
            <div className="flex flex-row mb-4 pl-5 gap-2">
            <Button variant="contained" onClick={() => setShowGraph(true)}>Show Graph</Button>
            <Button variant="contained" onClick={() => setShowGraph(false)}>Hide Graph</Button>
            </div>
            

            {showGraph == true ? (
              <div className=" max-sm:flex-col flex flex-row flex-wrap gap-5 ml-5 mr-5 max-sm:ml-0 max-sm:mr-0 mb-16">
                <div className="flex flex-row gap-1 w-full justify-center ">
            <Button variant="outlined" onClick={() => FilterTimeFunction("all")}>All </Button>
            <Button variant="outlined" onClick={() => FilterTimeFunction("1y")}>1 Year</Button>
            <Button variant="outlined" onClick={() => FilterTimeFunction("6m")}>6 Months</Button>
            <Button variant="outlined" onClick={() => FilterTimeFunction("1m")}>1 Month</Button>
            </div>
                <div className="max-w-full min-w-0 w-full  max-sm:w-full h-[40rem] bg-white soilmois">
                  <p className="relative z-20 text-lg font-bold  p-3 flex flex-row justify-between">
                    Bus Voltage (V)
                    <Button
                      sx={{
                        fontSize: "1.5rem",
                        backgroundColor: "",
                        padding: "0.3rem",
                        lineHeight: "1rem",
                        minWidth: "1rem",
                      }}
                      onClick={() => toggleFullscreen("graphbus_voltage")}
                    >
                      <img className="w-4 opacity-50" src={FullScreenSvg} />
                    </Button>
                  </p>
                      
                  {DrawStackedTSSMMemo}
                </div>
               
                
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </>
    </>
  );
};

const validateRequired = (value) => !!value.length;

export default SelectedIzci;

function StackedLine(rawdatas, names) {
  let option = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      icon: "roundRect",
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {},
      },
    },
    dataZoom: [
      {
        type: "slider",
        show: true,
        brushSelect: false,
        handleStyle: {
          borderColor: "rgba(0, 0, 0, 1)",
        },
        moveHandleSize: 16,
      },
      {
        type: "inside",
      },
    ],
    grid: [
      {
        left: "2%",
        right:"2%",
        top: "5%",
        containLabel: true,
      },
    ],
    axisLine: {
      show: false,
    },
    xAxis: {
      type: "time",
      axisPointer: {
        snap: false,
        lineStyle: {
          color: "#ff6600",
          width: 2,
        },
      },
    },
    yAxis: {
      splitLine: { show: false },
      splitArea: {
        show: true,
      },
      axisLine: { show: true },
      type: "value",
      max: function (value) {
        return (value.max + 5).toFixed(0);
      },
      min: function (value) {
        return (value.min - 0).toFixed(0);
      },
    },

    series: [],
    seriesCnt: "3",
    backgroundColor: "rgba(255,255,255,1)",
    titleColor: "#000000",
    subtitleColor: "#505050",
    textColorShow: false,
    textColor: "#333",
    markTextColor: "#ffffff",
    color: ["#2a10ac", "#00b7fb", "#df07dc","#ff6600"],
    borderColor: "#ccc",
    borderWidth: "0",
    visualMapColor: ["#8a7ca8", "#e098c7", "#cceffa"],
    legendTextColor: "#000000",
    kColor: "#e098c7",
    kColor0: "transparent",
    kBorderColor: "#e098c7",
    kBorderColor0: "#8fd3e8",
    kBorderWidth: "2",
    lineWidth: "1",

    symbol: "rect",
    symbolBorderWidth: "0",
    lineSmooth: false,
    graphLineWidth: 1,
    graphLineColor: "#aaaaaa",
    mapLabelColor: "#000",
    mapLabelColorE: "#ffffff",
    mapBorderColor: "#444",
    mapBorderColorE: "#444",
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: "#eee",
    mapAreaColorE: "#e098c7",
    axes: [
      {
        type: "all",
        name: "通用坐标轴",
        axisLineShow: true,
        axisLineColor: "#000000",
        axisTickShow: false,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#000000",
        splitLineShow: false,
        splitLineColor: ["#eeeeee", "#333333"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
      {
        type: "category",
        name: "类目坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: false,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "value",
        name: "数值坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "log",
        name: "对数坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "time",
        name: "时间坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
    ],
    axisSeperateSetting: false,
    toolboxColor: "#4100ff",
    toolboxEmphasisColor: "#000000",
    tooltipAxisColor: "#0013ff",
    tooltipAxisWidth: "3",
    timelineLineColor: "#8fd3e8",
    timelineLineWidth: 1,
    timelineItemColor: "#ff0000",
    timelineItemColorE: "#8fd3e8",
    timelineCheckColor: "#8fd3e8",
    timelineCheckBorderColor: "#8a7ca8",
    timelineItemBorderWidth: 1,
    timelineControlColor: "#8fd3e8",
    timelineControlBorderColor: "#8fd3e8",
    timelineControlBorderWidth: 0.5,
    timelineLabelColor: "#8fd3e8",
    datazoomBackgroundColor: "rgba(0,0,0,0)",
    datazoomDataColor: "rgba(255,255,255,0.3)",
    datazoomFillColor: "rgba(167,183,204,0.4)",
    datazoomHandleColor: "#a7b7cc",
    datazoomHandleWidth: "100",
    datazoomLabelColor: "#333",
  };
  rawdatas.map((e, index) => {
    option.series.push({
      name: names[index],
      type: "line",
      symbol: "none",
      symbolSize: "5",
      data: e,
      lineStyle: {
        width: 4,
      },
    });
  });
  return (
    <ReactEcharts
      option={option}
      id={`graph${names[0]}`}
      className={`graph${names[0]} z-10 `}
      style={{ height: '100%',width:'100%' }}
      lazyUpdate={true}
      opts={{ renderer: "svg" }}
      
    />
  );
}
